<style lang="less" scoped>

  @import "../../assets/css/variables";

  .content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .info-content {
    margin: 60px auto 0 auto;
    width: 400px;

  }
</style>

<template :is="view">
  <div class="content">
    <div class="info-content">
      <el-form ref="userForm" :rules="rules" :model="userInfo" label-width="80px">
        <el-form-item label="姓　　名" prop="name">
          <el-input v-model="userInfo.name"></el-input>
        </el-form-item>
        <!--<el-form-item label="公司名字">
          <el-input :disabled="true" v-model="userInfo.companyName"></el-input>
        </el-form-item>-->
        <el-form-item label="邮　　箱" prop="email">
          <el-input v-model="userInfo.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-row>
            <!--<div :span="12"><el-input  :disabled="true" v-model="user.mobile"></el-input></div>
            <div :span="12"><router-link  :span="12">重新绑定</router-link></div>-->
            <el-col :span="24">
              <el-input v-model="userInfo.mobile"></el-input>
            </el-col>
            <!-- <el-col :span="8" class="text-center">
               <router-link class="active-text" to="bindphone" :span="12">{{userInfo.mobile?'重新绑定':'绑定手机'}}</router-link>
             </el-col>-->
          </el-row>
        </el-form-item>
        <el-form-item class="m-b-xxl" prop="remarks" label="备　　注">
          <el-input type="textarea" :rows="3" v-model="userInfo.remarks"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="w-full" type="primary" size="large" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>

  //webpack 会将此转为base64 然后就可以用了，src一个静态本地想对路径图片的时候会被webpack url-loader拦截
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import cookie from '../../utils/http/cookie';
  import {API_ROOT}from '../../config';
  import {updateUserinfo} from '../../services/ucenter';
  import {mapGetters, mapActions} from "vuex";
  import {Notification} from "element-ui";
  import {valiEmail} from "../../utils/validator/element";
  export default {
    beforeRouteEnter(to, from, next) {
      next();
    },

    data() {

      return {
        userInfo: {
          name: '',
          email: '',
          remarks: '',
          mobile: ''
        },
        rules: {
          name: [
            {min: 2, max: 30, message: '长度在2到30个字符', trigger: 'blur'}
          ],
          position: [
            {min: 2, max: 20, message: '长度在2到20个字符', trigger: 'blur'}
          ],
          email: [
            {validator: valiEmail, trigger: 'blur'}
          ]
        }

      }
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
      /**
       * [更新用户信息]
       * @return {[type]} [description]
       */
      async updateUserinfo() {
        const { id, roleId, name, email, mobile, remarks } = this.userInfo
        const payload = { id, roleId, name, email, mobile, remarks }
        const userinfoData = await updateUserinfo(payload);
        if (userinfoData && userinfoData.errorCode === 0
          && userinfoData.data) {
          Notification.success({
            title: '成功',
            message: '您的信息更新成功'
          });
          let user = this.userInfo;
          cookie('userInfo', JSON.stringify(user));
          this.$store.dispatch('refreshUserInfo', user);
        }
      },
      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit() {
        this.$refs.userForm.validate((valid) => {
          if (valid) {
            this.updateUserinfo();
          }
        });
      }
    },
    created(){
      this.userInfo = Object.assign({}, this.user);
    },
    watch: {
      user(newVal, oldVal){
        if (newVal != oldVal) {
          this.userInfo = Object.assign({}, this.user);
        }
      }
    }
  }

</script>
